import { registerApplication, start } from "single-spa";
import Keycloak from "keycloak-js";
import { checkPath, checkSentryErrors } from "./helper/utils";
import * as Sentry from "@sentry/browser";
// import { BrowserTracing } from "@sentry/tracing";
import pjson from "../package.json";
import {
  getUserDetails,
  getMerchantConfig,
  loadTranslations,
} from "./services/common";
import { ignoredSentryErrors } from "./constants";

const isLocal = process.env.isLocal;

const eventData = [];
let realEstateDefaultDSN =
  "https://2173c4daf9c043ab95032a2f612edd8b@sentry.ottu.net/11";

let sentryUrl = "https://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};

  realEstateDefaultDSN = sentryUrl =
    "https://cc29b1e0b30c441a83ce84f12e46ca16@o1152525.ingest.sentry.io/6250727";
}

sentryUrl = process.env.VUE_APP_SENTRY_URL || sentryUrl;

Sentry.setTag("microfrontend", "root_conf");
Sentry.init({
  dsn: sentryUrl,
  release: `root_conf@${pjson.version}`,
  // integrations: [new BrowserTracing()],
  ignoreErrors: ignoredSentryErrors,
  beforeSend: checkSentryErrors,
  // tracesSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
  sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
  autoSessionTracking: false,
  enabled: !isLocal,
});

var token = "";
const state = [];
const isKeycloakAuth = process.env.ENABLE_KEYCLOACK === "true";
const enableCHD = process.env.VUE_APP_PAYMENT_ENABLE_CHD === "true";
const changeState = (value) => {
  state.push(value);
  if (value === "state") {
    getConfig().then((config) => {
      getUserDetail().then((user) => {
        createSocketConnection(user.id, config.websocket_url);
      });
    });
  }
};

const options = {
  pkceMethod: process.env.KEYCLOAK_PKCE_METHOD || "S256",
  url: `https://${process.env.VUE_APP_SSO_DOMAIN}/auth/`,
  realm: process.env.MERCHANT_ID,
  clientId: process.env.KEYCLOAK_CLIENT_ID || "frontend",
  onLoad: process.env.KEYCLOAK_ON_LOAD || "login-required",
};

const _keycloak = Keycloak(options);
const noNavBar = checkPath(window.location.pathname);

_keycloak
  .init({ checkLoginIframe: false })
  .then(async () => {
    if (!_keycloak.authenticated && !noNavBar && isKeycloakAuth) {
      _keycloak.login({
        redirectUri: window.location.href,
      });
    } else {
      token = _keycloak.idToken;
      setLocalStorage(
        _keycloak.tokenParsed && _keycloak.tokenParsed.exp,
        token
      );
      const arabicTranslation = await getTranslations();
      if (token) {
        const config = await getConfig();
        const user = await getUserDetail();
        createSocketConnection(user.id, config.websocket_url);
      }
      /*global System*/
      /*eslint no-undef: "error"*/
      registerApplication({
        name: "@ottu-mf/navigation",
        app: () =>
          System.import("@ottu-mf/navigation").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: "/",
        customProps: {
          idToken,
          refreshToken,
          logout,
          translations,
          arabicTranslation,
          ignoredSentryErrors,
          checkSentryErrors,
          ws_notification: eventData,
          noNavBar: noNavBar || location.pathname.includes("/duplicate"),
          serverOrigin: process.env.VUE_APP_CORE_BACKEND_URL,
          firebaseAPIKey:
            process.env.VUE_APP_API_KEY ||
            "AIzaSyCRENY_ACvWS3Gk-fqR9ZvfTZJ2iuT6kE8",
          firebaseProjectId:
            process.env.VUE_APP_PROJECT_ID || "ottu-order-notification",
          firebaseSenderID: process.env.VUE_APP_SENDER_ID || "300985765733",
          firebaseAppId:
            process.env.VUE_APP_APP_ID ||
            "1:300985765733:web:08bfa30600d34036c49b8c",
          firebasePublicKey:
            process.env.VUE_APP_FIREBASE_PUBLIC_KEY ||
            "BIzFlm-RgFHW7vbEBxw9ojAZpKqGBUv-QPMDWvYZcKAo6IUWBBlVMO0eayt8PdcwOz_YcmOBKHM7gN-JRpcwHy0",
          sentryUrl,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          // traceSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          isKeycloakAuth,
          state,
          isLocal,
        },
      });

      registerApplication({
        name: "@ottu-mf/frontend",
        app: () =>
          System.import("@ottu-mf/frontend").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [
          (location) => location.pathname.includes("/transactions"),
          (location) => location.pathname.includes("/bulk/"),
          (location) => location.pathname.includes("/catalogue"),
          (location) => location.pathname.includes("/customer-payment"),
          (location) => location.pathname.includes("/e-commerce"),
          (location) => location.pathname.includes("/event"),
          (location) => location.pathname.includes("/dashboard"),
          (location) => location.pathname.includes("/payment-request"),
          (location) => location.pathname.includes("/generated-reports"),
          (location) => location.pathname.includes("/food_ordering"),
          (location) => location.pathname.includes("/session"),
          (location) => location.pathname.includes("/shopify"),
          (location) => location.pathname.includes("/user"),
          (location) => location.pathname.includes("/help"),
          (location) => location.pathname.includes("/cpr"),
          (location) => location.pathname.includes("/checkout/"),
          (location) => location.pathname.includes("/events"),
          (location) => location.pathname.includes("/menu"),
          (location) => location.pathname.includes("/spf"),
          (location) => location.pathname.includes("/ticket"),
          (location) => location.pathname.includes("/pos"),
          (location) => location.pathname.includes("/real-estate"),
          (location) => location.pathname.includes("/search"),
          (location) => location.pathname.includes("/login"),
          (location) => location.pathname.includes("/forget-password"),
        ],
        customProps: {
          idToken,
          refreshToken,
          logout,
          translations,
          ignoredSentryErrors,
          checkSentryErrors,
          arabicTranslation,
          noNavBar,
          ws_notification: eventData,
          sentryUrl,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          // traceSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
          firebaseAPIKey:
            process.env.VUE_APP_API_KEY ||
            "AIzaSyCRENY_ACvWS3Gk-fqR9ZvfTZJ2iuT6kE8",
          firebaseProjectId:
            process.env.VUE_APP_PROJECT_ID || "ottu-order-notification",
          firebaseSenderID: process.env.VUE_APP_SENDER_ID || "300985765733",
          firebaseAppId:
            process.env.VUE_APP_APP_ID ||
            "1:300985765733:web:08bfa30600d34036c49b8c",
          firebasePublicKey:
            process.env.VUE_APP_FIREBASE_PUBLIC_KEY ||
            "BIzFlm-RgFHW7vbEBxw9ojAZpKqGBUv-QPMDWvYZcKAo6IUWBBlVMO0eayt8PdcwOz_YcmOBKHM7gN-JRpcwHy0",
          customStyleDirectoryName:
            process.env.VUE_APP_CUSTOM_STYLE_DIRECTORY_NAME,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          orderRefreshTime: process.env.VUE_APP_ORDER_LIST_REFRESH_TIME || 60,
          checkoutSDKLink:
            process.env.VUE_APP_CHECKOUT_SDK_URL ||
            "https://assets.ottu.net/checkout/v1/checkout.min.js",
          gatewayApiKey:
            process.env.VUE_APP_GATEWAY_API_KEY ||
            "L0Fc5f81.dLqByodGesaD9pJdzoKpo6rP1FQBkVzR",
          showFromforPaymentPage:
            process.env.VUE_APP_SHOW_FROM_IN_PAYMENT_DETAILS_PAGE === "false"
              ? false
              : true,
          isKeycloakAuth,
          changeState,
          enableCHD,
          isLocal,
        },
      });
      registerApplication({
        name: "@ottu-mf/estate",
        app: () =>
          System.import("@ottu-mf/estate").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [(location) => location.pathname.includes("/real_estate")],
        customProps: {
          idToken,
          logout,
          ws_notification: eventData,
          merchant: process.env.MERCHANT_ID,
          translations,
          ignoredSentryErrors,
          checkSentryErrors,
          sentryUrl: realEstateDefaultDSN,
          gatewayApiKey:
            process.env.VUE_APP_GATEWAY_API_KEY ||
            "L0Fc5f81.dLqByodGesaD9pJdzoKpo6rP1FQBkVzR",
          isLocal: isLocal,
        },
      });

      registerApplication({
        name: "@ottu-mf/fnb_frontend",
        app: () =>
          System.import("@ottu-mf/fnb_frontend").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [
          (location) => location.pathname.includes("/new-menu"),
          (location) => location.pathname.includes("/food-ordering"),
        ],
        customProps: {
          idToken,
          logout,
          ignoredSentryErrors,
          checkSentryErrors,
          arabicTranslation,
          ws_notification: eventData,
          merchant: process.env.VUE_APP_CORE_SERVER_MERCHANT,
          translations,
          sentryUrl,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          // traceSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
          noNavBar: noNavBar || location.pathname.includes("/duplicate"),
          orderRefreshTime: process.env.VUE_APP_ORDER_LIST_REFRESH_TIME || 60,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          isLocal,
        },
      });
    }
  })
  .catch((err) => {
    console.log("Error: ", err);
  });

export function idToken() {
  return token ? token : "";
}

export function logout() {
  if (isKeycloakAuth) {
    token = "";
    _keycloak.logout();
  }
}

export function translations() {
  /*global require*/
  /*eslint no-undef: "error"*/
  const translations = require.context(
    "./translations",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  translations.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = translations(key);
    }
  });
  return messages;
}

function setLocalStorage(token_expiry, token) {
  localStorage.setItem("isKeycloakAuth", isKeycloakAuth);

  if (isKeycloakAuth) {
    localStorage.setItem("VUE_APP_TOKEN", token);
    localStorage.setItem(
      "VUE_APP_SESSION_IDLE",
      (token_expiry - 60 - Date.now() / 1000) * 1000
    );
  } else {
    localStorage.setItem(
      "VUE_APP_SESSION_IDLE",
      process.env.VUE_APP_SESSION_IDLE
    );
  }

  localStorage.setItem(
    "VUE_APP_CORE_SERVER_ORIGIN",
    process.env.VUE_APP_CORE_BACKEND_URL
  );

  localStorage.setItem(
    "VUE_APP_GOOGLE_API_KEY",
    process.env.VUE_APP_GOOGLE_API_KEY ||
      "AIzaSyAzrErHOIrPyYNwwlIpbbDAB7L3jKsfkCA"
  );

  localStorage.setItem(
    "VUE_APP_POS_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_POS_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_POS_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_POS_PLUGIN_LANGUAGE_BUTTON || true
  );

  localStorage.setItem(
    "VUE_APP_EVENTS_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_EVENTS_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_EVENTS_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_EVENTS_PLUGIN_LANGUAGE_BUTTON || true
  );

  localStorage.setItem(
    "VUE_APP_CATALOGUE_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_CATALOGUE_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_CATALOGUE_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_CATALOGUE_PLUGIN_LANGUAGE_BUTTON || true
  );
  localStorage.setItem(
    "VUE_APP_CHECKOUT_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_CHECKOUT_PLUGIN_SOCIAL_ICONS || true
  );
  localStorage.setItem(
    "VUE_APP_CHECKOUT_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_CHECKOUT_PLUGIN_LANGUAGE_BUTTON || true
  );
  localStorage.setItem("MERCHANT_ID", process.env.MERCHANT_ID || "");
}

async function getConfig() {
  try {
    const res = await getMerchantConfig();
    return res.data;
  } catch (err) {
    console.log("Error while fetching config: ", err);
    return {};
  }
}

async function getUserDetail() {
  try {
    const res = await getUserDetails();
    return res.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      logout();
    }
    console.log("Error while fetching user details: ", err);
    return {};
  }
}

async function getTranslations() {
  try {
    const res = await loadTranslations();
    return res.data;
  } catch (err) {
    console.log("Error while fetching translations", err);
    return {};
  }
}

function createSocketConnection(id, url) {
  if (!url || !id) {
    console.log("WebSocket url and User id is required", url, id);
    return;
  }
  let socket = new WebSocket(url);
  const payload_dict = {
    id: {
      merchant_id: process.env.MERCHANT_ID,
      type: "frontend",
    },
    audience: {
      data: id.toString(),
    },
  };
  const pingInterval = 30000; // 30 seconds
  const pingTimeout = 10000; // 10 seconds

  let pingIntervalId;
  let pingTimeoutId;

  function pingWebsocket() {
    pingIntervalId = setInterval(() => {
      socket.send(JSON.stringify({ id: { type: "ping" } }));
      pingTimeoutId = setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          clearInterval(pingIntervalId);
          clearTimeout(pingTimeoutId);
          // call back here to make sure we don't send a timeout
          pingWebsocket();
        } else {
          socket.close();
          socket = new WebSocket(url);
        }
      }, pingTimeout);
    }, pingInterval);
  }
  socket.onopen = (event) => {
    pingWebsocket();
    socket.send(JSON.stringify(payload_dict));
  };

  socket.onmessage = (event) => {
    if (event.data && event.data === "pong") {
      clearTimeout(pingTimeoutId);
    } else {
      eventData.pop();
      eventData.push(event.data);
    }
  };

  socket.onerror = (event) => {
    console.log("error occurs", event);
    clearInterval(pingIntervalId);
  };

  socket.onclose = () => {
    console.log("Connection is closed...");
    clearInterval(pingIntervalId);
  };
}

const refreshToken = async () => {
  await _keycloak
    .updateToken(-1)
    .then(() => {
      if (token) {
        token = _keycloak.idToken;
      }
    })
    .catch((err) => {
      console.log("Error: ", err);
      logout();
    });
};

start();
